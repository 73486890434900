<template>
  <div class="cashing">
    <div class="cashing-head">
      <p>泡泡鱼兑换币</p>
      <p>
        {{content.coin}}
        <img
          src="https://ppyos.xijiuyou.com/imgs/smartgame/coin-cy.png"
          alt=""
        />
      </p>
      <div class="cashing-head-list" @click="cashinglist">兑换记录</div>
      <div class="cashing-bottom" @click="goloading">
        <p>邀请好友，可获得兑换币</p>
        <p>去获取</p>
      </div>
    </div>
    <div class="prize">兑换奖品</div>
    <!-- 兑换列表 -->
    <div class="cashing-list" :style="item.putaway ? 'height:43px;' : ''" v-for='(item,index) in content.prizes' :key="index">
      <div
        class="cashing-list-head"
        :style="
          'background: url('+item.barImage+') 0% 0% / 100% 100% no-repeat;'
        "
        @click="away(index)"
      >
        {{ !item.putaway ? "收起" : "展开"
        }}<img
          src="https://ppyos.xijiuyou.com/imgs/smartgame/pointer-game.png"
          alt=""
          :style="item.putaway ? 'transform: rotateX(180deg);' : ''"
        />
      </div>
      <div class="cashing-list-center">
        <div @click="Popups(center,item.gameId)"  v-for='(center,index_) in item.prizes' :key="index_">
          <img
            :src="center.props.code=='prizeGamePropsSendService'?center.props.icon:center.goodsProps.icon"
            alt=""
          />
          <p>{{center.name}}</p>
          <p>
            {{center.cost}}<img
              src="https://ppyos.xijiuyou.com/imgs/smartgame/coin-cy.png"
              alt=""
            />
          </p>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <xwpopup
      :isshowpopup="lotterycard"
      @close="closeHandle()"
      :hiddenClose="true"
    >
      <div class="content-bot" @click.self.prevent="closeHandle()">
        <div class="content-ex">
          <div class="card">
            <img
              :src="listcenter.props?listcenter.props.code=='prizeGamePropsSendService'?listcenter.props.icon:listcenter.goodsProps.icon:''"
              alt=""
            />
          </div>
          <div class="introduce">{{listcenter.name}}</div>
          <div class="prop">
            <p>
              {{listcenter.cost}}
              <img
                src="https://ppyos.xijiuyou.com/imgs/smartgame/coin-cy.png"
                alt=""
              />
            </p>
            <van-stepper v-model="value" min="1" :max="listcenter.maxCount!=0?listcenter.maxCount:'99'"  />
          </div>
          <div class="explain">
            <p>兑换说明</p>
            <p @click="lotterycard = false;" v-html="listcenter.goodsProps?listcenter.goodsProps.tips:''"></p>
          </div>
          <div class="active">
            <p @click="currency" :style="content.coin >= value*listcenter.cost?'':'background: #DDDDDD;'">{{content.coin >= value*listcenter.cost?'立即兑换':'兑换币不足'}}</p>
          </div>
        </div>
      </div>
    </xwpopup>

    <xwpopup :isshowpopup="profit" @close="closeprofit()" :hiddenClose="false">
      <div class="profit">
        <img
          src="https://ppyos.xijiuyou.com/imgs/smartgame/buy-success-card.png"
          alt=""
        />
        <div>
          <img
            :src="listcenter.props?listcenter.props.code=='prizeGamePropsSendService'?listcenter.props.icon:listcenter.goodsProps.icon:''"
            alt=""
          />
          <p>{{value}}{{listcenter.unit}}</p>
          <p>{{listcenter.name}}</p>
          <p @click="closeprofit">好的</p>
        </div>
      </div>
    </xwpopup>
  </div>
</template>
<script>
import xwpopup from "../../components/Popupbox.vue";
import active from '../../api/active'
import { Toast } from "vant";
import game from "../../api/game"
export default {
  components: {
    xwpopup,
  },
  data() {
    return {
      putaway: false,
      lotterycard: false,
      value: 1,
      profit:false,
      content:{},
      listcenter:{},
      gameId:'',
      currencychec:true,
    };
  },
  mounted(){
    this.coinsprizes();
    window.gogame=function(sgid){
      game.smartstart({gid:sgid}).then(res=>{
        try{
          window.android.openH5Game(res.data.link)
        }catch(err){
            console.log(err)
        }
        
      })
    }
  },
  methods: {
    goloading(){
      this.$router.push({
        name:'loading'
      })
    },
    cashinglist(){
      this.$router.push({
        name:'Activevalue'
      })
    },
    currency(){//立即兑换
    if(this.currencychec){
      this.currencychec = false;
      active.coinConvert({
        propId:this.listcenter.propId,
        gameId:this.gameId,
        count:this.value,
      }).then(res=>{
        this.lotterycard = false;
        if(res.status != 0){
          Toast(res.view);
          this.currencychec=true;
        }else{
          this.profit = true;
        }
        this.coinsprizes();
      })
    }
    },
    Popups(item,gameId){//唤起弹窗
      this.gameId = gameId;
      this.lotterycard=true;
      this.listcenter = item
    },
    coinsprizes(){//奖品列表
      active.coinsprizes().then(res=>{
        this.content = res.data
        this.content.prizes.map((res,index)=>{
          this.content.prizes[index]['putaway']=false;
        })
      })
    },
    away(index) {//展开隐藏
      this.content.prizes[index].putaway = !this.content.prizes[index].putaway;
      this.$forceUpdate()
    },
    closeprofit(){//关闭弹窗
        this.profit = false;
        this.currencychec = true;
    },
    closeHandle() {//关闭弹窗
      this.lotterycard = false;
    },
  },
};
</script>
<style scoped>
.cashing {
  background: #f6f6f6;
  padding-top: 12px;
}
.cashing-head {
  width: calc(100% - 24px);
  margin: 0 auto;
  text-align: center;
  background: #ffffff;
  border-radius: 15px;
  padding: 0 14px;
  padding-top: 17px;
  padding-bottom: 14px;
  position: relative;
}
.cashing-head-list{
  position: absolute;
  right: 0;
  top: 0;
  width: 85px;
  height: 30px;
  background: rgba(255, 175, 52, 0.09);
  border-radius: 0px 13px 0px 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF9736;
}
.cashing-head p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a05632;
}
.cashing-head p:nth-child(2) {
  font-size: 30px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #ff9736;
  margin-top: 8px;
}
.cashing-head p img {
  width: 20px;
  height: 20px;
}
.prize {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 20px 0 10px;
  padding-left: 12px;
}
.cashing-list {
  width: calc(100% - 24px);
  margin: 0 auto;
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px;
}
.cashing-list-head {
  width: 100%;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.cashing-list-head img {
  width: 7px;
  margin-left: 3px;
}
.cashing-list-center {
  display: flex;
  padding: 14px 12px;
  flex-wrap: wrap;
}
.cashing-list-center div {
  width: 31%;
  height: 145px;
  background: url('https://ppyos.xijiuyou.com/imgs/invite/act/prop-bg.png') no-repeat;
  background-size: 100%;
  border-radius: 10px;
  text-align: center;
  position: relative;
  margin: 0 2.5px;
  margin-bottom: 8px;
  padding-top: 7px;
  box-sizing: border-box;
}
.cashing-list-center div img {
  width: 53px;
  margin-bottom: 7px;
}
.cashing-list-center div p {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #874c27;
}
.cashing-list-center div p:nth-child(3) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 37px;
  background: linear-gradient(270deg, #ffe5bb 0%, #ffefd4 100%);
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  font-size: 18px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #ff9736;
}
.cashing-list-center div p img {
  width: 20px;
  height: 20px;
  margin: 0;
  margin-left: 3px;
}

.content-ex {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: #fff;
  border-radius: 20px 20px 0 0;
  padding-bottom: 18px;
  padding-top: 16px;
}
.card {
  width: calc(100% - 32px);
  margin: 0 auto;
  height: 113px;
  background: url("https://ppyos.xijiuyou.com/imgs/smartgame/bg-color-card.png")
    no-repeat;
  background-size: 100% 100%;
  padding-top: 11px;
}
.card img {
  width: 68px;
}
.introduce {
  margin-top: 10px;
  font-size: 16px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #444444;
  text-align: left;
  padding-left: 20px;
}
.explain {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: left;
  padding: 0 18px;
  margin-top: 43px;
  margin-bottom: 37px;
  line-height: 25px;
}
.active {
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
}
.active p {
  width: 100%;
  height: 48px;
  background: linear-gradient(90deg, #ff8c81 0%, #ff6c87 100%);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.explain p span {
  color: rgba(255, 86, 117, 1);
}
.prop {
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
  align-items: center;
  font-size: 20px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #ff9736;
  margin-top: 20px;
}
.prop img {
  width: 20px;
  height: 21px;
  margin-left: 3px;
  margin-top: -3px;
}
.prop p {
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.content-bot{
    width: 100%;
    height: 100%;
}

.profit {
  margin-top: 120px;
}
.profit img:nth-child(1) {
  width: calc(100% - 102px);
}
.profit div {
  width: 305px;
  background: linear-gradient(180deg, #fff7e8 0%, #ffffff 100%);
  box-shadow: inset 0px 2px 0px 0px #ffffff;
  border-radius: 15px;
  margin: -4px auto 0;
  padding-bottom: 30px;
}
.profit div img {
  margin-top: -24px;
}
.profit div img:nth-child(1){
    width: 68px;
}
.profit div p:nth-child(2) {
  font-size: 18px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ff5675;
  margin-top: 20px;
}
.profit div p:nth-child(3) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5675;
  margin-top: 8px;
}
.profit div p:nth-child(4) {
  width: 197px;
  height: 44px;
  background: linear-gradient(314deg, #ff5675 0%, #ff886d 100%);
  border-radius: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 0;
}
.cashing-bottom{
  width: 100%;
  height: 40px;
  background: linear-gradient(270deg, rgba(255, 208, 138, 0.29) 0%, rgba(255, 227, 180, 0.29) 100%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px 0 14px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #A05632;
  margin-top: 22px;
}
.cashing-bottom p:nth-child(2){
  width: 70px;
  height: 24px;
  background: linear-gradient(270deg, #FFB953 0%, #FFC86C 100%);
  border-radius: 17px;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  margin: 0;
}
</style>

<style>
  .explain p a {
    color: RGBA(68, 154, 255, 1);
  }
  .van-stepper{
    padding: 4px;
    border-radius: 8px;
    background: RGBA(246, 246, 246, 1);
  }
  .van-stepper__minus{
    background: #ffffff;
  }
  .van-stepper__plus{
    background: #ffffff;
  }
  .van-stepper__minus::before{
    width: 30%;
  }
  .van-stepper__plus::before{
    width: 30%;
  }
  .van-stepper__plus::after{
    height: 30%;
  }
  .van-stepper__input{
    background: RGBA(246, 246, 246, 1);
  }
</style>