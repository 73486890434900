import axios from "axios";
import qs from'qs';
const active = {
  prizes() {
    //礼品数据
    return axios.get("/api/ppy/usercenter/activity/prizes");
  },
  exchange(data) {
    //兑换
    return axios.post("/api/ppy/usercenter/activity/exchange",qs.stringify(data));
  },
  coinConvert(data) {
    //兑换奖品
    return axios.post("/api/ppy/usercenter/coins/coinConvert",qs.stringify(data));
  },
  exchangeHis(data) {
    //兑换记录
    return axios.get("/api/ppy/usercenter/activity/exchangeHis", {
        params:{
            page:data,
            pageSize:20
        }
      });
  },
  coinscoinConvert(data) {
    //邀请bi兑换记录
    return axios.get("/api/ppy/usercenter/coins/coinConvert", {
        params:{
            page:data,
            pageSize:20
        }
      });
  },
  inviteAggregation() {
    //我的邀请统计
    return axios.get("/api/ppy/usercenter/invite/inviteAggregation");
  },
  tasks() {
    //邀请任务
    return axios.get("/api/ppy/usercenter/invite/newTasks");
  },
  inviteActionLog() {
    //邀请任务
    return axios.get("/api/ppy/usercenter/invite/inviteActionLog");
  },
  coinsprizes() {
    //获取可兑换的礼物列表
    return axios.get("/api/ppy/usercenter/coins/prizes");
  },
  myFriende(data,id) {
    //兑换
    return axios.get("/api/ppy/usercenter/invite/myFriend", {
      params:{
          page:data,
          pageSize:20,
          searchId:id?id:undefined
      }
    });
  },
  // 话费列表
  records(data){
    return axios.get("/api/ppy/usercenter/telPhoneBill/records", {
      params:{
          page:data,
          pageSize:20
      }
    });
  }
};
export default active;
